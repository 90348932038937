/*@font-face {
font-family: 'Avenir LT';
font-style: normal;
font-weight: normal;
src: local('Avenir LT Std 55 Roman'), url('./font/AvenirLTStd-Roman.woff') format('woff');
}


@font-face {
font-family: 'Avenir LT';
font-style: normal;
font-weight: normal;
src: local('Avenir LT Std 55 Oblique'), url('./font/AvenirLTStd-Oblique.woff') format('woff');
}
*/

/*@font-face {
font-family: 'Avenir LT';
font-style: normal;
font-weight: normal;
src: local('Avenir LT Std 35 Light'), url('./font/AvenirLTStd-Light.woff') format('woff');
}


@font-face {
font-family: 'Avenir LT';
font-style: normal;
font-weight: normal;
src: local('Avenir LT Std 35 Light Oblique'), url('./font/AvenirLTStd-LightOblique.woff') format('woff');
}
*/

@font-face {
  font-family: "Avenir LT";
  font-style: normal;
  font-weight: 400;
  src: local("Avenir LT Std 45 Book"),
    url("./font/AvenirLTStd-Book.woff") format("woff");
}

@font-face {
  font-family: "Avenir LT";
  font-style: italic;
  font-weight: 400;
  src: local("Avenir LT Std 45 Book Oblique"),
    url("./font/AvenirLTStd-BookOblique.woff") format("woff");
}

/*@font-face {
font-family: 'Avenir LT';
font-style: normal;
font-weight: normal;
src: local('Avenir LT Std 65 Medium'), url('./font/AvenirLTStd-Medium.woff') format('woff');
}


@font-face {
font-family: 'Avenir LT';
font-style: normal;
font-weight: normal;
src: local('Avenir LT Std 65 Medium Oblique'), url('./font/AvenirLTStd-MediumOblique.woff') format('woff');
}
*/

@font-face {
  font-family: "Avenir LT";
  font-style: normal;
  font-weight: 700;
  src: local("Avenir LT Std 85 Heavy"),
    url("./font/AvenirLTStd-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Avenir LT";
  font-style: italic;
  font-weight: 700;
  src: local("Avenir LT Std 85 Heavy Oblique"),
    url("./font/AvenirLTStd-HeavyOblique.woff") format("woff");
}

@font-face {
  font-family: "Avenir LT";
  font-style: normal;
  font-weight: 900;
  src: local("Avenir LT Std 95 Black"),
    url("./font/AvenirLTStd-Black.woff") format("woff");
}

@font-face {
  font-family: "Avenir LT";
  font-style: italic;
  font-weight: 900;
  src: local("Avenir LT Std 95 Black Oblique"),
    url("./font/AvenirLTStd-BlackOblique.woff") format("woff");
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: "Avenir LT", sans-serif;
  font-weight: 400;
  height: 100%;
  background-image: url("./img/bg-repeat.png");
  background-repeat: repeat;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button {
  font-family: "Avenir LT", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
